.banner{
min-height: 540px;
&.project{

}
  &.small{
      height: 45vh;
  }
  &.medium{
      height: 65vh;
      @media screen and (max-width:768px){
        height: 90vh;
      }
  }

  &.full{
    height: 85vh;
  }
}


.banner_overlay{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
pointer-events: none;

&.banner_top{
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5858718487394958) 100%);

&.light{
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.5858718487394958) 100%);
}

  }
  &.banner_bottomLeft{
background:  linear-gradient(199deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.6) 100%) 0% 0% ;
}
  &.banner_bottom{
    background: linear-gradient(0deg, rgba(0,0,0,0.5886729691876751) 0%, rgba(0,0,0,0) 64%, rgba(0,0,0,0) 100%);
&.light{
  background: linear-gradient(0deg, rgba(255,255,255,0.5886729691876751) 0%, rgba(255,255,255,0) 64%, rgba(255,255,255,0) 100%);
}

  }

  &.banner_full{
    background: rgba(0,0,0,0.3);

    &.light{
      background: rgba(255,255,255,0.4);
        }
    }
  }

  .banner-vid{

    @media screen and (max-height:600px){
          min-width: 1120px;
    }
    @media screen and (max-height:800px){
          min-width: 1300px;
    }

    @media screen and (max-height:1000px){
          min-width: 1480px;
    }

    @media screen and (min-height:1001px){
          min-width: 1690px;
    }
  }
