.featured-work-carousel {
    position: relative
}

.featured-work-carousel__nav {
    position: absolute;
    z-index: 4;
    bottom: 5%;
    left: 0;
    width: 100%;
    height: auto;
    text-align: center
}

@media (max-width: 1023px) {
    .featured-work-carousel__nav {
        display:none
    }
}

.featured-work-carousel__nav ul {
    display: block;
    line-height: 1
}

.featured-work-carousel__nav li {
    display: inline-block;
    position: relative;
    margin: 0 1.3rem;
    width: 1.6rem;
    height: 1.6rem
}

.featured-work-carousel__nav li.active:after,.featured-work-carousel__nav li.active:hover {
    opacity: 1!important
}

.featured-work-carousel__nav li:hover:after {
    opacity: .5
}

.featured-work-carousel__nav li:after {
    content: "";
    display: block;
    position: absolute;
    top: .6rem;
    left: .6rem;
    width: .4rem;
    height: .4rem;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .3s cubic-bezier(.72,.16,.345,.875)
}

.featured-work-carousel__nav li button {
    display: inline-block;
    -webkit-appearance: none;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    outline: 0;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 0;
    position: relative;
    z-index: 1;
    width: 300%;
    height: 300%;
    background-color: transparent;
    color: transparent;
    margin-left: -100%;
    margin-top: -100%
}

.featured-work-carousel__nav li svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.6rem;
    height: 1.6rem
}

.featured-work-carousel__nav li svg path {
    stroke-width: 2px;
    stroke: #fff;
    fill: none
}

.featured-work-carousel__nav li svg .circle--bg {
    opacity: .3
}

.featured-work-carousel__next-image {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 90.85106%;
    width: 25%;
    height: 100%;
    cursor: pointer;
    padding: 1.70213%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.featured-work-carousel__next-image button {
    display: inline-block;
    -webkit-appearance: none;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    outline: 0;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 0;
    background-color: transparent;
    color: #fff;
    margin: 0;
    padding: 0;
    display: block;
    text-align: left
}

.featured-work-carousel__next-image button span {
    display: block;
    text-transform: none;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    overflow: hidden
}

.featured-work-carousel__next-image button .inner {
    margin: 0
}

.featured-work-carousel__next-image svg {
    width: 1.8rem;
    height: .7rem;
    fill: #fff;
    stroke: #fff;
    margin-left: .75rem
}

.featured-work-carousel__images {
    position: relative;
    overflow: hidden
}

.featured-work-carousel__images:before {
    content: " ";
    float: left;
    padding-top: 150%
}

.featured-work-carousel__images:after {
    content: " ";
    display: table;
    clear: both
}

.featured-work-carousel__images>:first-child {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%
}

@media (min-width: 550px) {
    .featured-work-carousel__images:before {
        content:" ";
        float: left;
        padding-top: 125%
    }

    .featured-work-carousel__images:after {
        content: " ";
        display: table;
        clear: both
    }

    .featured-work-carousel__images>:first-child {
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%
    }
}

@media (min-width: 768px) {
    .featured-work-carousel__images:before {
        content:" ";
        float: left;
        padding-top: 66.66667%
    }

    .featured-work-carousel__images:after {
        content: " ";
        display: table;
        clear: both
    }

    .featured-work-carousel__images>:first-child {
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%
    }
}

@media (min-width: 1280px) {
    .featured-work-carousel__images:before {
        content:" ";
        float: left;
        padding-top: 42.40882%
    }

    .featured-work-carousel__images:after {
        content: " ";
        display: table;
        clear: both
    }

    .featured-work-carousel__images>:first-child {
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%
    }
}

.featured-work-carousel__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.featured-work-carousel__image.entering {
    z-index: 2
}

.featured-work-carousel__image.will-enter {
    z-index: 1
}

.featured-work-carousel__image.active {
    z-index: 3
}

.featured-work-carousel__image.active .card--featured-work__image {
    -webkit-transform: translateX(-9.14894%);
    transform: translateX(-9.14894%)
}

.featured-work-carousel__image.active .card--featured-work__image .layer {
    opacity: 1
}

.featured-work-carousel__image .card--featured-work__image .layer {
    opacity: .2
}

.featured-work-carousel .image-carousel__image:first-child {
    margin-left: -1rem
}

@media (min-width: 1024px) {
    .featured-work-carousel .image-carousel__image:first-child {
        margin-left:0
    }
}
