/// Nav layout
.nav {
  ul {

  }
  .nav_link {
    font-size: 0.7em;
    font-weight: 500;

    a{
      color:#fff;
    }
    }

    @media screen and (max-width: 640px) {
      .nav_link {
        display: none;
        &:first-child{
          display: block;
          z-index: 10;
        }
      }
      }

  }


.mobileMenu{
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 40;
  left:-100%;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);

  &.active{
    left:0%;
    transition: 0.4s;
    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
  }
}
