// Effects
/*effect-underline*/
a.effect-underline {
  border: none;
  position: relative;
}
a.effect-underline:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 7px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0,1);
  transform: scale(0,1);
}
a.effect-underline--grey:after {
  border-bottom: $mid-grey 1px solid;
}
a.effect-underline:hover:after {
  opacity: 1;
  transform: scale(1);
}

.smart-arrow{
  #Path_29{
    transform: translate(5743.908, -0.606);
    d: path("M-4691.719,5160.5h48.338");
    transition: 0.2s;
    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
  }

    #Path_30{

      transform: translate(5736.056, 3.904);
    d: path("M-4657.864,5150.369l5.465,5.464-5.465,5.465");
      transition: 0.4s;
      transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
    }
}
.hover-btn:hover .smart-arrow {
  #Path_29{
    transform: translate(5727.038, -0.606) !important;
  d: path("M-4691.719,5160.5h48.338");
    transition: 0.4s;
    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
  }

  #Path_30{
    transform: translate(5736.056, 3.904) !important;
  d: path("M-4657.864,5150.369l5.465,5.464-5.465,5.465");
    transition: 0.4s;
    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
  }
}

a{
  text-decoration: underline;
  color: #4EA2E0
}

// .nav-slider li {
// position: relative;
//    a:after{
//     content: "";
//     position: absolute;
//     width: 100px;
//     left:0;
//     right:0;
//     height: 2px;
//     background-color: #fff;
//     bottom: -10px;
//     opacity: 0;
//       transition: 0.2s
// }
// &:hover a:after{
//   transform: rotate(-22deg);
//   padding-bottom: 10%;
//   opacity: 1;
//   transition: 0.2s
// }
// }

.demo .menu-icon {
  transform: scale(1.5);
}

:root {
  --bar-bg: #fff;
}

.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.menu-icon .menu-icon__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}
.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 33px;
  height: 16px;
}
.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--bar-bg, #000);
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
  top: 0;
}
.menu-icon span:last-of-type {
  bottom: 0;
}
.menu-icon.active span:first-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
  transform: rotate(45deg);
  top: 7px;
}
.menu-icon.active span:last-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 7px;
}
.menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
  width: 33px;
}
@media (min-width: 1024px) {
  .menu-icon:hover span:first-of-type {
    width: 26px;
  }
  .menu-icon:hover span:last-of-type {
    width: 12px;
  }
}
