// General stuff
ul.circle-checkmark {
	li {
  line-height: 1.3;

		&::before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      border: solid 10px #000;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
		}
    &::after {
      content: "";
      position: absolute;
      left: 7px;
      top: 6px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
	}
}

a{
  cursor: pointer
}
