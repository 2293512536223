.svg-item{
  width: 100% !important;
  height: 100% !important;
}

.work-item{

  &.is-animating{
    animation: workangle 1.6s;
    animation-timing-function: cubic-bezier(.79,0,.35,1);
  }
}

.info-box{
opacity: 0;
transition: 1s;
position: relative;
margin-bottom: -100px;
}

.slider-item{

  &.is-animating{
    animation: topangle 1.2s;
    animation-timing-function: cubic-bezier(.79,0,.35,1);

    .info-box{
      transition-duration: 1s;
      transition-delay: 600;
      transition-timing-function: cubic-bezier(.79,0,.35,1);
      opacity: 1;

      margin-bottom:0px;
    }
  }
}


.bottom_angle{
  position: relative;
  z-index: -1;

  &:before{
    content: "";
    z-index: -1;
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: #000;
  }

&.is-animating:before{
  animation: bottomangle 1.6s;
  animation-timing-function: cubic-bezier(.79,0,.35,1);
}
}

.pre_angle{
  position: relative;
  z-index: -1;
&:before{
  content: "";
  z-index: -1;
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color: #f7fafc;
}

&.is-animating:before{
  animation: angle 1.6s;
  animation-timing-function: cubic-bezier(.79,0,.35,1);
}

img{
  visibility: visible;
  opacity: 0;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 150, 0, 1);
}

.text{
  visibility: visible;
  opacity: 0;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 150, 0, 1);
}

.rightColumn{
  visibility: visible;
  opacity: 0;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 150, 0, 1);
}

.leftColumn{
  visibility: visible;
  opacity: 0;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 150, 0, 1);
}

&.is-animating{
  img{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 800ms, transform 1s cubic-bezier(0.5, 0, 0, 1) 800ms;
  }
  .text{
    visibility: visible;
    opacity: 1;

    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 1s cubic-bezier(0.5, 0, 0, 1) 840ms, transform 1s cubic-bezier(0.5, 0, 0, 1) 840ms;
  }

  .leftColumn{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1) 800ms, transform 0.6s cubic-bezier(0.5, 0, 0, 1) 800ms;
  }

  .rightColumn{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1) 1000ms, transform 0.6s cubic-bezier(0.5, 0, 0, 1) 1000ms;

  }
}
}

.work-project{
.bg-image{
  opacity: 1;
  transition: opacity 0.5s;
}

.info-overlay{
  opacity: 1;
  transition: opacity 1s;
}

.banner-overlay{
  opacity: 1;
  transition: opacity 1s;
}

&:hover {
  .bg-image{
  opacity: 0;
  transition: opacity 1s;
}
.info-overlay{
  opacity: 0.4;
  transition: opacity 1s;
}

.banner-overlay{
  opacity: 0;
  transition: opacity 1s;
}

}
}


@keyframes angle {
  0%{
    clip-path: polygon(0 73%, 100% 50%, 100% 100%, 0% 100%);
  }

  100%{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
@keyframes workangle {
  0%{
    clip-path: polygon(0 70%, 100% 42%, 100% 100%, 0% 100%);

  }

  100%{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@keyframes bottomangle {
  0%{
    clip-path: polygon(0 0, 100% 0, 100% 56%, 0 46%);


  }

  100%{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@keyframes topangle {
  0%{
  clip-path: polygon(0 23%, 100% 34%, 100% 100%, 0 100%);
  }

  100%{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
