// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  @extend %font-sans-regular;
  @include font-values(15,15);
  letter-spacing: 0.04em;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: solid 1px;
  padding: 0 20px;
  border-radius: 3px;
  line-height: 45px;
  text-align: center;
  transition: all 0.6s ease-in-out;

  &__primary {
    background-color: $brand-color;
    border-color: $brand-color;
    color: #fff;
  }
}

.tabs {
  @extend %font-sans-regular;
  @include font-values(18,18);
}

.visible-e {
  display: block !important;
  opacity: 1 !important;
}

.active {
  opacity: 1 !important;
}

.mobile-view iframe{
  width: 400px;
}

.mobile-view .browser-bar{
  display: none;
}

.desktop-view{
  width:100%;
iframe{
  width: 100%;
  border:none !important;
  border-radius: 0px !important;
  padding: 0px !important;


}
.responsive-scale-wrapper___GDhAN{
@media (max-width:1060px){
display: none;
}
}
@media (max-width:1059px){
&:after{
  content: "Please open on a larger device to the desktop version on this project.";
  font-size: 16px;

}
}
  height: 800px;
  width: 90%;
  max-width: 400px;
  margin:70px auto;
}

.responsive-scale-wrapper___GDhAN{
  transform: translateY(-2.55795e-14px) scale(0.9);
  @media (max-height:870px){
transform: translateY(-8.55795e-14px) scale(0.7);
  }
}

.mobile-view{
.responsive-scale-wrapper___GDhAN{
            margin-top: -10px;
    @media (max-height:870px){
          margin-top: -60px;
    }
}
}
.desktop-view{
.responsive-scale-wrapper___GDhAN{
            margin-top: -60px;
    @media (max-height:870px){
          margin-top: -120px;
    }
}
}

.iframe-container{
  height: 800px;
  @media (max-height:870px){

  }
}
