
.slide {
    transition-duration: 1s;
    left: 0%;
    top: 0%;
}

.slide.prev {
    left: -80%;
    top: 0%;
    z-index: 11;
    transition: left;
    transition-duration: 0.9s;
    transition-timing-function: cubic-bezier(.79,0,.35,1)
 /* custom */
}

.next-text {
    left: 0%;
}

.slide.current {
    left: 0%;
    top: 0%;
    z-index: 10;
    transition-duration: 0.9s;
        transition-timing-function: cubic-bezier(.79,0,.35,1)
}

.slide-delay{
      transition-delay: 0.18s;
}

.hover-current:hover ~ .slide.current {
    margin-left: -2%;
    transition-duration: 0.2s;
}

.slide.next {
    left: 22%;
    top: 0%;
    transition-duration: 0.7s;

 /* custom */
}

.slide.next > .shadow {
    background: #0007;
    transition-duration: 0.5s;
}

.slide.await {
    left: 25%;
    top: 0%;
    transition-duration: 0.5s;
    z-index: -10;
}

.hover-current:hover ~ .slide.next {
    left: 21%;
    transition-duration: 0.5s;
}

.hover-current:hover ~ .hover-next > .next-text {
    left: -5%;
    transition-duration: 0.5s;
}

.hover-current:hover ~ .slide.next > .shadow {
    background: #0007;
    transition-duration: 0.5s;
}
.hover-next{
  cursor: pointer;
}
.hover-next:hover ~ .slide.next {
    left: 20%;
    transition-duration: 0.5s;
}

.hover-next:hover .next-text {
    left: -12%;
    transition-duration: 0.5s;
}

.hover-next:hover ~ .slide.next > .shadow {
    background: #0005;
    transition-duration: 0.5s;
}

.hover-next:hover ~ .slide.current {
    left: -4%;
    transition-duration: 0.5s;
}

.slide img {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    max-width: unset;
}

.swiper-button-next, .swiper-button-prev{
  background: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

border-radius: 999px;
  &:after{
    color: #000;
    font-size: 14px !important;

  }
}

.media-slider .swiper-button-next, .media-slider .swiper-button-prev{
width:20vw;
height:100%;
background: transparent;
z-index: 10;
}

.media-slider .swiper-slide{
  opacity: 0.5;
}

.media-slider .swiper-slide-active{
  opacity: 1;
}



.title-overlay{
  opacity: 0;
  margin-bottom: -20px;
  transition: 0.4s;
}

.swiper-slide-active .title-overlay{
  opacity: 1;
  margin-bottom: 30px;
  transition: 0.7s;
  transition-delay: 300;
  transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
}

// .info-box{
// opacity: 1;
// margin-bottom: -100px;
// transition: 0.7s;
// transition-delay: 300;
// transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
// h2{
//   position: relative;
//      opacity: 0;
//   transition: 0.4s;
//  bottom:-40px;
//    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
// }
// h4{
//     position: relative;
//      opacity: 0;
//     bottom:-40px;
//     transition: 0.4s;
//      transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
// }
// a{
//     position: relative;
//      opacity: 0;
//     bottom:-40px;
//     transition: 0.4s;
//      transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
// }
// }
//
// .slide.current .info-box{
// opacity: 1;
// margin-bottom: 0;
// transition: 0.7s;
// transition-delay: 0.5s;
// transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
//  h2{
//    bottom:0;
//    opacity: 1;
//    transition: 0.5s;
//    transition-delay: 0.9s;
//    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
//  }
//  h4{
//    transition-delay: 0.8s;
//    opacity: 1;
//       transition: 0.4s;
//    bottom:0;
//    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
//  }
//  a{
//    transition-delay: 1s;
//    opacity: 1;
//       transition: 0.5s;
//    bottom:0;
//    transition-timing-function: cubic-bezier(0.785, 0.010, 0.385, 0.995);
//  }
// }

.info-box {
  transition-duration: 0.7s;
}

.active {
  transform: translateY(0);
  opacity: 0.99 !important;

    @media screen and (min-width:640px){
  transform: translateY(20%);

  opacity: 0 !important;
  }
  transition-duration: 0.5s;
}

.info-box-inner {
  @media screen and (max-width: 639px) {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.7s;
  }

}

.active .info-box-inner{
  @media screen and (max-width:639px){
transform: translateY(20%);
transition-duration: 0.5s;
opacity: 0 !important;
}
}

@keyframes infobox-animation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(20%);
    opacity: 0 !important;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.slide-video{
  @media screen and (max-width:639px){
min-width: 600px;
}
  @media screen and (min-width:640px){
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    max-width: fit-content;

  }
}

.welcome-height {
  @media screen and (min-width:640px){
    height:300px;
        @media screen and (min-height:800px){
    height:30vh;
  }
  }
}

.slider-height{
    @media screen and (min-width:640px){
      height:70vh;
                min-height: 600px;
          @media screen and (min-height:800px){
      height:calc(70vh - 98.23px);
      min-height: inherit;
    }

    }
    height: 550px;
}

.media-slider .swiper-pagination{
  margin-top: 20px;
  border:none;


.swiper-pagination-bullet{
  width: 10px;
  height:10px;
  border:solid 1px #000;
  border-radius: 9999px;
  display: inline-block;
    transition: 0.2s;
  &-active{
    background-color: #000;
    width: 15px;
    height:15px;
    margin-bottom: -2px !important;
      transition: 0.2s;
  }

}
}
.pricing-slider .swiper-pagination-bullet{
width: 100%;
}


.page-slide img{
    transition: 2s;
}

.page-slide:hover img{
  transform: scale(1.1);
  transition: 2s;
}

.tab-swiper-content{
  .swiper-pagination-bullet{
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: transparent;

    &:focus{
      outline: none;
    }
  }
}
.multi-tab{
.swiper-slide{
  @media screen and (min-width:768px){
        width: max-content;
  }
  @media screen and (max-width:767px){
        width: max-content;
  }
}

.multi-tab-img{

  @media screen and (min-width:768px){
      height: 400px;
  }

  @media screen and (max-width:767px){
      max-height: 250px;
      max-width: 90vw;
  }

}
}


.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.swiper-container {
  width: 100%;
  height: 100%;
  transform: translateX(0);
  transition:1s;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}

.translate-next{
    transform: translateX(-100px);
    transition:1s;
}
