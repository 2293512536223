/**
 * Basic typography style for copy text
 */
 // FONT CLASSES

%font-sans-regular {
  font-family: $regular-font-stack;
  font-weight: 300;
  font-style: normal;
  @include alias;
}

%font-sans-light {
  font-family: $regular-font-stack;
  font-weight: 200;
  font-style: normal;
  @include alias;
}

%font-sans-semi-bold {
  font-family: $regular-font-stack;
  font-weight: 600;
  font-style: normal;
  @include alias;
}

%font-sans-bold {
  font-family: $regular-font-stack;
  font-weight: 700;
  font-style: normal;
  @include alias;
}

%font-alt-regular {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 400;
  @include alias;
}

%font-alt-bold {
  font-family: $alt-font-stack;
  font-style: normal;
  font-weight: 700;
  @include alias;
}

%font-alt-black {
  font-family: $alt-font-stack;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  @include alias;
}

body {
  color: $text-color;
  @extend %font-sans-regular;
  @include font-values(24,27);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  hyphens: none;
}

h1 {
  @extend %font-alt-black;
  @include fluid-type(36px, 70px);
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 2rem;
  strong{
    font-weight: 100;
    color: #000
  }

  &.alt{
      @extend %font-sans-regular;
  }

}

h2 {
  @extend %font-alt-black;
  @include fluid-type(32px, 45px);
  line-height: 1.2;
  strong{
    font-weight: 100;
    color: #000
  }

  &.alt{
      @include fluid-type(28px, 32px);
      text-transform: none;
      @extend %font-sans-regular;
  }
}

h3 {
  @extend %font-sans-regular;
  @include font-values(22,24);
    line-height: 1.4;
    

}

h4 {
  @extend %font-sans-regular;
  @include fluid-type(22px, 26px);
  color: #505050;
    line-height: 1.5;
    font-weight: 400;
}

h5 {
  @include fluid-type(18px, 16px);
  font-weight: 600;
}

h6{
  @include fluid-type(18px, 20px);
  margin-top: 0;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0.7rem;
  color: #718096;
}

.small {
  @include font-values(14,17);
}
.medium {
  @include font-values(16,18);
  line-height: 1.3;
}
.big {
  @include font-values(24,27);
  line-height: 1.3;
}
.huge {
  @include font-values(27,30);
  line-height: 1.3;
}
p {
  @include fluid-type(16px, 18px);
  margin-top: 0;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0.7rem;
  color: #718096;



  b, strong{
    color: #222427;
  }


}


.text-service .serviceSection p{
  color:#718096;
  font-size: 14px;
  margin-bottom: 5px;
}

.serviceSection h4{
    color:#fff;
}

footer p{
  color:#fff;
  line-height: 1.4;
}

footer h6{
    font-size: 0.8rem;
    opacity: 0.7;
  }

.contact a{
  color: #fff;
  margin-bottom: 5px;
  text-decoration: none;
  &:hover{
    color: #fff;
  }


}

  .service p{
    color: #7D7D7D;
      @include fluid-type(16px, 18px);
  }

  .service ul{
    margin-top: 15px;
    padding-left:20px;

    li{
      @include fluid-type(16px, 18px);
      font-weight: 400;
      list-style: disc;
      opacity: 0.5;
      padding: 8px 0;
    }
  }

// Typography helpers
.bold {
  @extend %font-alt-bold;
}
.sans {
  @extend %font-sans-regular;
}
.serif {
  @extend %font-alt-regular;
}

.top-heading {
  @apply max-w-md;
}
